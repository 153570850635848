import { IObjectivePrivacyOption } from "./objectives.interfaces"

export enum PrivacyOption {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
}

export const OBJECTIVE_PRIVACY_OPTIONS: IObjectivePrivacyOption[] = [
  {
    name: "Public",
    description: "Anyone you follow can view this",
    key: PrivacyOption.PUBLIC,
  },
  { name: "Private", description: "Only you can view this", key: PrivacyOption.PRIVATE },
]

export enum ObjectiveStatus {
  ACTIVE = "public",
  INACTIVE = "private",
  SUGGESTED = "suggested",
}

export const FILLER_CLAUSES = ["learn how to", "learn to", "how to"]
