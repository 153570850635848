import { useEffect, useState } from "react"
import { updateNodes } from "../../../utils/tree"
import { FlattenedItem } from "../../core/components/Tree/types"

const useObjectiveTree = (items: any[]) => {
  const [initialItems, setInitialItems] = useState<FlattenedItem[]>([])
  const [currentItems, setCurrentItems] = useState<FlattenedItem[]>([])

  const onOrderChange = (newItems: FlattenedItem[]) => {
    setCurrentItems(newItems)
  }

  useEffect(() => {
    if (!items) return

    update(items)
  }, [items])

  const update = (items: FlattenedItem[]) => {
    let updatedItems: FlattenedItem[] = items.map((objective) => {
      return {
        ...objective,
        children: (objective.children as FlattenedItem[]) || [],
        parentId: null,
        depth: 0,
        index: 0,
        collapsed: true,
      }
    })

    updatedItems = updateNodes(updatedItems as any[], {
      collapsed: true,
    }) as any[]

    setInitialItems(updatedItems)
    setCurrentItems(updatedItems)
  }

  return {
    initialItems,
    setInitialItems,
    currentItems,
    setCurrentItems,
    onOrderChange,
    update,
  }
}

export default useObjectiveTree
