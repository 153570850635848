import { UniqueIdentifier } from "@dnd-kit/core"
import { IObjective } from "../src/pages/api/objectives/getSubObjectives"

export interface BasicTreeNode {
  id: UniqueIdentifier
  name: string
  children?: BasicTreeNode[]
}

export interface TreeNode extends BasicTreeNode {
  children: TreeNode[]
  parentId?: UniqueIdentifier
  depth: number
  index: number
}

export interface Action {
  type: "add" | "remove"
  parentId: UniqueIdentifier
  childId: UniqueIdentifier
}

export function findNode(tree: TreeNode[], id: UniqueIdentifier): TreeNode | null {
  for (const node of tree) {
    if (node.id === id) {
      return node
    }
    if (node.children) {
      const found = findNode(node.children, id)
      if (found) return found
    }
  }
  return null
}

export function buildPath(tree: TreeNode[], nodeId: UniqueIdentifier): TreeNode | null {
  const node = findNode(tree, nodeId)
  if (!node) return null

  let path: TreeNode = { ...node, children: [] }
  let current = path

  while (current.parentId) {
    const parentNode = findNode(tree, current.parentId)
    if (!parentNode) break

    // Clone the parent node and set the current path as its child
    const parentClone: TreeNode = { ...parentNode, children: [current] }
    path = parentClone // Update the path to the new root
    current = path // Move current up to the parent
  }

  return path
}

export function findAndUpdateNodeChildren(
  items: TreeNode[],
  itemId: UniqueIdentifier,
  children: IObjective[],
  ignoreByFields?: string[]
): TreeNode[] {
  function updateNode(node: TreeNode, ignoreByFields?: string[]): boolean {
    if (node.id === itemId) {
      // Node is found, update its children
      const newChildren: any = children.map((child, idx) => ({
        id: -parseInt(node.id + "0000000" + idx),
        depth: node.depth + 1,
        parentId: node.id,
        index: idx,
        ...child,
      }))
      if (ignoreByFields?.length) {
        node.children = node.children.filter((child) => {
          for (let field of ignoreByFields) {
            if (!(field in child)) {
              // Remove child
              return false
            }
          }
          return true
        })
      }

      node.children.push(
        ...newChildren.filter((child) => !node.children.some((c) => c.id === child.id))
      )
      return true
    }

    // Recursively search in children
    for (let child of node.children) {
      if (updateNode(child as any)) {
        return true
      }
    }

    return false
  }

  items.forEach((item) => updateNode(item, ignoreByFields))
  return items
}

export function updateNodeById(items: TreeNode[], id: UniqueIdentifier, object: any): TreeNode[] {
  function updateNode(node: TreeNode): boolean {
    if (node.id === id) {
      // Node is found, update its properties
      Object.assign(node, object)

      return true
    }

    // Recursively search in children
    for (let child of node.children) {
      if (updateNode(child as any)) {
        return true
      }
    }

    return false
  }

  items.forEach((item) => {
    if (updateNode(item)) {
      return items
    }
  })
  return items
}

export function updateNodes(items: TreeNode[], object: any): TreeNode[] {
  function updateNode(node: TreeNode): void {
    // Update the current node
    Object.assign(node, object)

    // Recursively update the children
    node.children.forEach((child) => {
      updateNode(child as any)
    })
  }

  items.forEach((item) => {
    updateNode(item)
  })
  return items
}

export function deleteNodeById(items: TreeNode[], id: UniqueIdentifier): TreeNode[] {
  function deleteNode(node: TreeNode): boolean {
    if (node.id === id) {
      // Node is found, remove it
      return true
    }

    // Recursively search in children
    for (let child of node.children) {
      if (deleteNode(child as any)) {
        node.children = node.children.filter((c) => c.id !== id)
        return true
      }
    }

    return false
  }

  items.forEach((item) => {
    if (item.id === id) {
      items = items.filter((c) => c.id !== id)
      return items
    }
    deleteNode(item)
  })
  return items
}

export function findDifferences(prevState: TreeNode[], currState: TreeNode[]): Action[] {
  const actions: Action[] = []

  const prevMap = new Map<UniqueIdentifier, TreeNode>()
  const currMap = new Map<UniqueIdentifier, TreeNode>()

  function buildMap(nodes: TreeNode[], map: Map<UniqueIdentifier, TreeNode>) {
    nodes.forEach((node) => {
      map.set(node.id, node)
      if (node.children.length > 0) {
        buildMap(node.children, map)
      }
    })
  }

  buildMap(prevState, prevMap)
  buildMap(currState, currMap)

  function compareChildren(
    prevChildren: TreeNode[],
    currChildren: TreeNode[],
    parentId: UniqueIdentifier
  ) {
    const prevIds = new Set(prevChildren.map((child) => child.id))
    const currIds = new Set(currChildren.map((child) => child.id))

    prevChildren.forEach((child) => {
      if (!currIds.has(child.id)) {
        actions.push({ type: "remove", parentId, childId: child.id })
      }
    })

    currChildren.forEach((child) => {
      if (!prevIds.has(child.id)) {
        actions.push({ type: "add", parentId, childId: child.id })
      }
    })
  }

  function traverse(prevNode: TreeNode | null, currNode: TreeNode | null) {
    if (prevNode && currNode) {
      compareChildren(prevNode.children, currNode.children, prevNode.id)
      const prevChildrenMap = new Map(prevNode.children.map((child) => [child.id, child]))
      const currChildrenMap = new Map(currNode.children.map((child) => [child.id, child]))

      prevNode.children.forEach((child) => {
        traverse(child, currChildrenMap.get(child.id) || null)
      })

      currNode.children.forEach((child) => {
        if (!prevChildrenMap.has(child.id)) {
          traverse(null, child)
        }
      })
    } else if (prevNode) {
      prevNode.children.forEach((child) => {
        actions.push({ type: "remove", parentId: prevNode.id, childId: child.id })
        traverse(child, null)
      })
    } else if (currNode) {
      currNode.children.forEach((child) => {
        actions.push({ type: "add", parentId: currNode.id, childId: child.id })
        traverse(null, child)
      })
    }
  }

  const prevRootMap = new Map(prevState.map((node) => [node.id, node]))
  const currRootMap = new Map(currState.map((node) => [node.id, node]))

  prevState.forEach((node) => {
    traverse(node, currRootMap.get(node.id) || null)
  })

  currState.forEach((node) => {
    if (!prevRootMap.has(node.id)) {
      traverse(null, node)
    }
  })

  // Create a map to track cancellations
  const cancellationMap = new Map<string, boolean>()

  // First pass: mark cancellations
  actions.forEach((action) => {
    const key = `${action.parentId}-${action.childId}`
    if (cancellationMap.has(key)) {
      cancellationMap.set(key, true) // Mark as cancelled
    } else {
      cancellationMap.set(key, false) // Not cancelled yet
    }
  })

  return actions.filter((action) => {
    const key = `${action.parentId}-${action.childId}`
    return !cancellationMap.get(key)
  })
}

export function mapTree(node: TreeNode, transform: (node: TreeNode) => any): any {
  // Apply the transformation function to the current node
  const transformedNode = transform({ ...node })

  // Recursively apply the transformation to each child
  if (node.children && node.children.length > 0) {
    transformedNode.children = node.children.map((child) => mapTree(child, transform))
  } else {
    transformedNode.children = []
  }

  return transformedNode
}

export function flattenTree(node: TreeNode): TreeNode[] {
  let flatList: TreeNode[] = [node] // Start with the current node

  // Recursively flatten each child and concatenate the result
  if (node.children) {
    node.children.forEach((child) => {
      flatList = flatList.concat(flattenTree(child))
    })
  }

  return flatList
}

export function getAllChildren(node: TreeNode): TreeNode[] {
  let children: TreeNode[] = []

  if (node.children) {
    node.children.forEach((child) => {
      children.push(child)
      children = children.concat(getAllChildren(child))
    })
  }

  return children
}

export function getChildrenByField(items: TreeNode[], field: string): TreeNode[] {
  // Recursively search in children for the first node that has the field
  function findChild(node: TreeNode): TreeNode | null {
    if (field in node) {
      return node
    }

    for (let child of node.children) {
      const found = findChild(child as any)
      if (found) return found
    }

    return null
  }

  const children: TreeNode[] = []
  items.forEach((item) => {
    const child = findChild(item)
    if (child) {
      children.push(child)
    }
  })

  return children
}

export function getRootNodeByChildId(items: TreeNode[], childId: number): TreeNode | undefined {
  // Helper function to find a node by id
  function findNodeById(nodes: TreeNode[], id: number): TreeNode | undefined {
    for (const node of nodes) {
      if (node.id === id) {
        return node
      }
      const found = findNodeById(node.children, id)
      if (found) {
        return found
      }
    }
    return undefined
  }

  // Helper function to find the root node given a node
  function findRootNode(node: TreeNode, nodes: TreeNode[]): TreeNode {
    if (node.parentId === undefined) {
      return node
    }
    const parentNode = findNodeById(nodes, node.parentId as number)
    if (parentNode) {
      return findRootNode(parentNode, nodes)
    }
    return node
  }

  // Start by finding the node with the given childId
  const childNode = findNodeById(items, childId)
  if (!childNode) {
    return undefined
  }

  // Find the root node starting from the found child node
  return findRootNode(childNode, items)
}

export function findRootAndFlattenTree(items: TreeNode[], nodeId: UniqueIdentifier): TreeNode[] {
  // Helper function to find the root node
  function findRootNode(node: TreeNode): TreeNode | null {
    if (!node.parentId) {
      return node
    }
    const parentNode = findNode(items, node.parentId)
    if (parentNode) {
      return findRootNode(parentNode)
    }
    return null
  }

  // Start by finding the node with the given ID
  const node = findNode(items, nodeId)
  if (!node) {
    return [] // Return an empty array if the node is not found
  }

  // Find the root node from the found node
  const rootNode = findRootNode(node)
  if (!rootNode) {
    return [] // Return an empty array if the root node is not found
  }

  // Flatten the tree from the root node
  return flattenTree(rootNode)
}
